import React, { useState } from "react";
import { ValidationError } from "@formspree/react";

export default function ContactForm({ state, handleSubmit }) {
  const [formData, setFormData] = useState({
    name: "",
    lastname: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="form-contact">
      <div className="name-lastname-div">
        <div className="name-lastname-div-inputs me-3">
          <label htmlFor="name">Nome *</label>
          <input
            id="name"
            type="text"
            name="name"
            maxLength="20"
            required
            value={formData.name}
            onChange={handleInputChange}
          />
          <ValidationError prefix="Name" field="name" errors={state.errors} />
        </div>
        <div className="name-lastname-div-inputs">
          <label htmlFor="lastname">Sobrenome *</label>
          <input
            id="lastname"
            type="text"
            name="lastname"
            maxLength="20"
            required
            value={formData.lastName}
            onChange={handleInputChange}
          />
          <ValidationError
            prefix="LastName"
            field="lastname"
            errors={state.errors}
          />
        </div>
      </div>
      <label htmlFor="email">Email *</label>
      <input
        id="email"
        type="email"
        name="email"
        maxLength="40"
        required
        value={formData.email}
        onChange={handleInputChange}
      />
      <ValidationError prefix="Email" field="email" errors={state.errors} />
      <label htmlFor="subject">Assunto *</label>
      <input
        id="subject"
        type="text"
        name="subject"
        maxLength="20"
        required
        value={formData.subject}
        onChange={handleInputChange}
      />
      <ValidationError prefix="Subject" field="subject" errors={state.errors} />
      <label htmlFor="message">Mensagem *</label>
      <textarea
        id="message"
        name="message"
        maxLength="250"
        required
        value={formData.message}
        onChange={handleInputChange}
      />
      <ValidationError prefix="Message" field="message" errors={state.errors} />
      <button type="submit" disabled={state.submitting}>
        Enviar
      </button>
    </form>
  );
}
