// ** Projects Images
import proj1 from "./assets/imgs/projects/project1.png";
import proj2 from "./assets/imgs/projects/project2.png";
import proj3 from "./assets/imgs/projects/project3.png";
import proj4 from "./assets/imgs/projects/project4.jpg";

export const projectsData = [
  {
    id: 1,
    image: proj1,
    title: "FlorExpress",
    path: "/projeto/florexpress",
  },
  {
    id: 2,
    image: proj4,
    title: "TerraTech",
    path: "/projeto/terratech",
  },

  {
    id: 3,
    image: proj2,
    title: "Pet Track",
    path: "/projeto/pettrack",
  },
  {
    id: 4,
    image: proj3,
    title: "Endy",
    path: "/projeto/endy",
  },
];

export const aboutInfo = [
  {
    id: 1,
    text: "User Experience",
  },
  {
    id: 2,
    text: "UX Research",
  },
  {
    id: 3,
    text: "Usability Test",
  },
  {
    id: 4,
    text: "Benchmarking",
  },
  {
    id: 5,
    text: "Design System",
  },
  {
    id: 6,
    text: "User Interface Design",
  },
  {
    id: 7,
    text: "Wireframes",
  },
  {
    id: 8,
    text: "Prototype",
  },
  {
    id: 9,
    text: "Responsive Design",
  },
  {
    id: 10,
    text: "Design Thinking",
  },
  {
    id: 11,
    text: "HTML/CSS",
  },
  {
    id: 12,
    text: "Figma",
  },
];
