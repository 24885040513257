import Introduction from "./Introduction";
import Projects from "./Projects";
import About from "./About";
import BannerMessage from "./BannerMessage";
import Contact from "./Contact";

export default function Home() {
  return (
    <>
      <Introduction />
      <Projects />
      <About />
      <BannerMessage />
      <Contact />
    </>
  );
}
