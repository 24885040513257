import { Container } from "react-bootstrap";
import { aboutInfo } from "../data";

export default function About() {
  return (
    <section id="about">
      <Container>
        <h2>Olá, eu sou a Karla</h2>
        <p>
          Acredito que em um mundo que está em constante evolução da tecnologia,
          o Designer de UX/UI é um catalisador de inovação, antecipando as
          tendências do futuro e moldando o que está por vir.
        </p>
        <p>
          Sou uma solucionadora incansável de problemas que decifra os desafios
          mais complexos, utilizando de princípios como a empatia,
          acessibilidade e inclusão, para garantir que a experiência do usuário
          com a tecnologia seja fácil, intuitiva e otimizada.
        </p>
        <h4>Formação</h4>
        <ul>
          <li>Google l Profissional Google UX Design</li>
          <li>
            University of Minnesota l Specialization User Interface Design
          </li>
        </ul>
        <h4>Skills</h4>
        <div className="sobre-mim-div">
          {aboutInfo.map((btn) => (
            <span key={btn.id} className="skills-card">
              {btn.text}
            </span>
          ))}
        </div>
        <a className="cv-link" href="https://drive.google.com/file/d/1wjC65LWcSebCvP5xZuhG0pn_iii4AQY1/view?usp=sharing" target="_blank" rel="noreferrer">
          Currículo
        </a>
      </Container>
    </section>
  );
}
