import { useEffect } from "react";
import { Container } from "react-bootstrap";
import agradecimentosPetTrack from "../../assets/imgs/projects/pettrack/agradecimento_pettrack.png";
import petTrackImg from "../../assets/imgs/projects/pettrack/pettrack.png";

export default function PetTrack() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <section className="pettrack">
      <Container>
        <img src={petTrackImg} alt="PetTrack Prototype" />
        <iframe
          width="600"
          height="450"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F1v7Z2yaTQBT1YeBvLTM9Zx%2FPet-Track%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D1-2%26viewport%3D452%252C581%252C0.5%26t%3DRziIW0nC9XfZim3A-1%26scaling%3Dscale-down%26starting-point-node-id%3D1%253A2%26mode%3Ddesign"
          title="Figma Protoype PetTrack"
          allowFullScreen
        ></iframe>
        <img
          src={agradecimentosPetTrack}
          alt="Agradecimentos"
          className="agradecimentos-pettrack"
        />
      </Container>
    </section>
  );
}
