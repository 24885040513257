import { useEffect } from "react";
import { Container } from "react-bootstrap";
import terratech from "../../assets/imgs/projects/terratech/terratech.jpg";

export default function TerraTech() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <section className="terratech">
      <Container>
        <img src={terratech} alt="TerraTech Prototype" />
        <iframe
          className="mt-5"
          width="600"
          height="450"
          src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F6Zhv8iIQ8oH5dabM1Gz32T%2FAutoU---Case-Tecnico%3Fpage-id%3D0%253A1%26type%3Ddesign%26node-id%3D259-2486%26viewport%3D13%252C158%252C0.13%26t%3DxcQDf9JIWK2CS27b-1%26scaling%3Dscale-down%26starting-point-node-id%3D259%253A2486%26mode%3Ddesign"
          title="Figma Protoype TerraTech"
          allowFullScreen
        ></iframe>
      </Container>
    </section>
  );
}
