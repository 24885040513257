import { useState } from "react";
import { Link } from "react-router-dom";

export default function SingleProjectDiv({ image, title, path }) {
    const [show, setShow] = useState(false)

    return (
        <div className="project-div"
            onMouseOver={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
        >
            <Link to={path}>
                <img src={image} alt={title} />
                <div className={show ? 'project-div-title height-fifth' : 'project-div-title height-zero'}>
                    <span>{title}</span>
                </div>
            </Link>
        </div>
    )
}