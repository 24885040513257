import { useEffect } from "react"
import { Container } from "react-bootstrap"
import agradecimentos from '../../assets/imgs/projects/florexpress/agradecimentos.png'
import florexpress from '../../assets/imgs/projects/florexpress/florexpress.png'

export default function FlorExpress() {
  
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, [])

  return (
    <section className="florexpress">
    <Container>
      <img src={florexpress} alt="FlorExpress Prototype" />
      <iframe width="600" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FAej2i2mFwQy6ityd6keCXO%2FFlorExpress---Google-UX%3Ftype%3Ddesign%26node-id%3D369-191%26t%3DNeBOxTTP1QF8Wcvy-1%26scaling%3Dscale-down%26page-id%3D204%253A2%26starting-point-node-id%3D317%253A181%26mode%3Ddesign" 
      title="Figma Protoype FlorExpress" allowFullScreen></iframe>
      <img src={agradecimentos} alt="Agradecimentos" />
    </Container>
  </section>
  )
}