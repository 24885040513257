import { Container } from "react-bootstrap"

export default function BannerMessage() {
    return (
        <section id="banner">
            <Container>
                <h2>Design is not just what it looks like, design is how it works — Steve Jobs</h2>
            </Container>
        </section>
    )
}