import { Container } from "react-bootstrap"

export default function ErrorPage() {
    return (
        <section className="error-page">
            <Container>
                <h1>404 - Not Found</h1>
            </Container>
        </section>
    )
}