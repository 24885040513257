import { useEffect } from "react";
import { useCurrentContext } from "../context/context";

const ScrollSpy = () => {
    const { current, setCurrent } = useCurrentContext()

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            const navLi = document.querySelectorAll(".nav-link"); 
            let fromTop = window.scrollY;
            sections.forEach((section) => {
                const sectionTop = section.offsetTop - 120;
                const sectionHeight = section.offsetHeight;
                if (fromTop >= sectionTop && fromTop < sectionTop + sectionHeight) {
                    setCurrent(section.getAttribute("id") ?? "");
                }
            });
            navLi.forEach((li) => {
                li.classList.remove("active");
                if (li.classList.contains(current)) {
                    li.classList.add("active");
                }
            });
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [current, setCurrent]);

    return null; // or render your component
};

export default ScrollSpy;
