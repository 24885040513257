import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import NavbarBt from "./components/NavbarBt";
import Footer from "./components/Footer";
import ErrorPage from "./pages/ErrorPage";
import Home from './pages/Home'
import ScrollSpy from "./components/ScrollSpy";
import { CurrentProvider } from "./context/context";

//** Single Projects
import PetTrack from "./pages/single-projects/PetTrack";
import TerraTech from "./pages/single-projects/TerraTech"
import FlorExpress from "./pages/single-projects/FlorExpress"
import Endy from "./pages/single-projects/Endy"

export default function App() {
  return (
    <Router>
      <CurrentProvider>
        <NavbarBt />
        <ScrollSpy />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/projeto/pettrack' element={<PetTrack />} />
          <Route path='/projeto/terratech' element={<TerraTech />} />
          <Route path='/projeto/florexpress' element={<FlorExpress />} />
          <Route path='/projeto/endy' element={<Endy />} />
          <Route path='*' element={<ErrorPage />} />
        </Routes>
        <Footer />
      </CurrentProvider>
    </Router>
  )
}