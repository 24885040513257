import { useEffect } from "react";
import { Container } from "react-bootstrap";

export default function Endy() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 200);
  }, []);

  return (
    <section className="endy">
      <Container>Em Construção</Container>
    </section>
  );
}
