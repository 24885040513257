import React, { useState, useContext } from 'react'

const CurrentContext = React.createContext()

export const CurrentProvider = ({ children }) => {
    const [current, setCurrent] = useState("")

    return (
        <CurrentContext.Provider value={{ setCurrent, current }}>
            {children}
        </CurrentContext.Provider>
    )
}

export const useCurrentContext = () => {
    return useContext(CurrentContext)
}